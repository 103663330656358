body.basket.confirm,
body.basket.complete {
    .upper-confirm-button {
        margin-bottom: @grid-gutter-width;

        @media (min-width: @screen-sm-min) {
            text-align: right;
        }
    }

    .basket-products {
        padding-top: (@grid-gutter-width / 2);
        margin-bottom: @grid-gutter-width;

        @media (min-width: @screen-sm-min) {
            padding-top: @grid-gutter-width;
            margin-bottom: (@grid-gutter-width * 1.5);
        }
    }

    .tax-report {
        margin: @grid-gutter-width 0;
        padding-top: (@grid-gutter-width / 2);
    }

    .basket-actions {
        .flex-row;
        align-items: center;
        margin-bottom: @grid-gutter-width;

        .edit-basket-link,
        .confirm-button-wrap {
            @media (max-width: @screen-xxs-max) {
                flex-basis: 100% !important;
                margin: (@grid-gutter-width / 2) 0;
                text-align: center;
            }
        }

        .confirm-button-wrap {
            @media (min-width: @screen-xs-min) {
                text-align: right;
            }
        }
    }

    .actions {
        margin-bottom: @grid-gutter-width;
    }
}
